import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { country } from '@features/country/data';
import { TranslateModule } from '@ngx-translate/core';
import {
  NewIncentiveProgramModel,
  PreviousIncentiveSummaryModel,
} from 'src/app/core/domain/new-incentive-program.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetNewIncentiveProgramDetailsUseCase } from 'src/app/core/usecases/get-new-incentive-program-details.usecase';
import {
  dateFormat,
  incentiveDateFormat,
  INCENTIVE_IMG_URL,
  snakeCaseDateFormat,
} from '../../shared/constants';
import { WEB_DYNAMIC_INCENTIVE_PROGRAM } from '../../shared/constants/feature-flags';
import { CurrencyShortNamePipe } from '../../shared/pipes/currency-short-name.pipe';
import { MultitenancyService } from '../../shared/services/multitenancy.service';
import { DateWrapperUtility } from '../../shared/utilities/date-wrapper.utility';
import {
  CURRENT_MONTH_ARABIC_NAME,
  NEXT_MONTH_ARABIC_NAME,
  PREVIOUS_MONTH_ARABIC_NAME,
} from '../../shared/utilities/get-month.utility';
import { DownloadAppComponent } from '../../wallet/download-app/download-app.component';
import { DynamicIncentiveProgramComponent } from './dynamic-incentive-program/dynamic-incentive-program.component';
import { MultipleDynamicIncentiveProgramComponent } from './multiple-dynamic-incentive-program/multiple-dynamic-incentive-program.component';

@Component({
  selector: 'app-incentive-program',
  templateUrl: './incentive-program.component.html',
  styleUrls: ['./incentive-program.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgFor,
    MatExpansionModule,
    DynamicIncentiveProgramComponent,
    DownloadAppComponent,
    TranslateModule,
    CurrencyShortNamePipe,
    MatDialogModule,
    MultipleDynamicIncentiveProgramComponent,
  ],
})
export class IncentiveProgramComponent implements OnInit {
  public incentiveAssets = INCENTIVE_IMG_URL;

  public lastLevelFlag = false;

  public levelZeroFlag = false;

  public lowDR = false;

  public lowPreviousDR = false;

  public runnerProgress: number;

  public currentMonth = CURRENT_MONTH_ARABIC_NAME;

  public previousMonth = PREVIOUS_MONTH_ARABIC_NAME;

  public nextMonth = NEXT_MONTH_ARABIC_NAME;

  public dateFormat = dateFormat;

  public currentIncentiveData?: NewIncentiveProgramModel;

  public currentIncentiveDataStartDate: string;

  public currentIncentiveDataEndDate: string;

  public previousIncentiveDataDeliveryCalculationDate: string;

  public previousIncentiveData?: PreviousIncentiveSummaryModel;

  public snakeCaseDateFormat = snakeCaseDateFormat;

  public currency: string;

  public rewardMultiplierIsAvailable: boolean;

  private _dateFormat: string = incentiveDateFormat;

  isKsaSelected = false;

  public isDynamicIncentiveProgramEnabled = false;

  constructor(
    private _getNewIncentiveProgramDetailsUseCase: GetNewIncentiveProgramDetailsUseCase,
    private multitenancyService: MultitenancyService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
  ) {}

  ngOnInit(): void {
    this.getIncentiveProgramDetails();
    this.isKsaSelected = country.is('KSA');
    this.checkDynamicIncentiveProgram();
  }

  getIncentiveProgramDetails(): void {
    this.currency = this.multitenancyService.getCurrentCountry().currency.arabicName;
    this._getNewIncentiveProgramDetailsUseCase.execute().subscribe({
      next: (incentiveData) => {
        this.currentIncentiveData = incentiveData?.currentIncentive;
        this.previousIncentiveData = incentiveData?.prevIncentiveSummary;
        if (this.currentIncentiveData) {
          this.currentIncentiveDataStartDate = DateWrapperUtility.formatDateToString(
            new Date(this.currentIncentiveData.startDate),
            this._dateFormat,
          );
          this.currentIncentiveDataEndDate = this.getFormattedDate(
            this.currentIncentiveData.endDate,
          );
          this.checkLevelZeroFlag();
          this.checkLastLevelFlag();
          this.checkLowDRFlag();
          this.calculateRunningProgress();
          this._logMixpanelEventUseCase.execute({
            eventName: 'current_professional_challenge_page_load',
            payload: this.currentIncentiveData,
          });
          this.rewardMultiplierIsAvailable = this.currentIncentiveData.bonusLevels.length > 0;
        }
        if (this.previousIncentiveData) {
          this.previousIncentiveDataDeliveryCalculationDate = this.getFormattedDate(
            this.previousIncentiveData.deliveryCalculationDate,
          );
          this.checkLowPreviousDRFlag();
          this._logMixpanelEventUseCase.execute({
            eventName: 'previous_professional_challenge_page_load',
            payload: this.currentIncentiveData,
          });
        }
      },
    });
  }

  getFormattedDate(date: string): string {
    return DateWrapperUtility.formatDateToUTCString(new Date(date), this._dateFormat);
  }

  calculateRunningProgress(): void {
    this.runnerProgress =
      (this.currentIncentiveData!.deliveredOrders /
        this.currentIncentiveData!.targetDeliveredOrders) *
      100;
  }

  checkLevelZeroFlag(): void {
    if (this.currentIncentiveData!.currentLevel === 0) {
      this.levelZeroFlag = true;
    }
  }

  checkLastLevelFlag(): void {
    if (this.currentIncentiveData!.currentLevel === this.currentIncentiveData!.levels.length) {
      this.lastLevelFlag = true;
    }
  }

  checkLowDRFlag(): void {
    if (this.currentIncentiveData!.deliveryRate < this.currentIncentiveData!.minimumDeliveryRate) {
      this.lowDR = true;
    }
  }

  checkLowPreviousDRFlag(): void {
    if (
      this.previousIncentiveData!.deliveryRate < this.previousIncentiveData!.minimumDeliveryRate
    ) {
      this.lowPreviousDR = true;
    }
  }

  checkDynamicIncentiveProgram(): void {
    this._getFeatureFlagUsecase.execute(WEB_DYNAMIC_INCENTIVE_PROGRAM).subscribe({
      next: (flag) => {
        this.isDynamicIncentiveProgramEnabled = flag;
      },
    });
  }
}
