<div
  class="incentive-container"
  *ngIf="!isDynamicIncentiveProgramEnabled || (!isKsaSelected && isDynamicIncentiveProgramEnabled)"
>
  <div *ngIf="currentIncentiveData">
    <div class="incentive-container__header">
      <div class="incentive-container__header__right">
        <div class="incentive-container__header__right__image">
          <img src="{{ incentiveAssets + 'incentive-truck.svg' }}" />
        </div>
        <div class="incentive-container__header__right__content">
          <p class="incentive-container__header__right__content__header heading2--bold">
            {{ currentIncentiveData.arabicName }}
          </p>
          <div
            *ngIf="currentIncentiveData.renewalDate || currentIncentiveData.startDate"
            class="incentive-container__header__right__content__description"
          >
            <img src="{{ incentiveAssets + 'calendar.svg' }}" />
            <p class="incentive-container__header__right__content__description__text body2--medium">
              <span>{{ 'INCENTIVE_PROGRAM.HEADER.CHALLENGE_DATES.TEXT_1' | translate }}</span>
              <span class="incentive-container__header__right__content__description__text__date">{{
                currentIncentiveDataStartDate
              }}</span>
              <span>{{ 'INCENTIVE_PROGRAM.HEADER.CHALLENGE_DATES.TEXT_2' | translate }}</span>
              <span class="incentive-container__header__right__content__description__text__date">{{
                currentIncentiveDataEndDate
              }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="incentive-container__header__left">
        <p class="incentive-container__header__left__black body1--bold">
          {{ 'INCENTIVE_PROGRAM.HEADER.TEXT_1' | translate }}
        </p>
      </div>
    </div>
    <div class="incentive-container__cards">
      <div class="incentive-container__cards__right" [ngClass]="levelZeroFlag ? 'full-width' : ''">
        <div class="incentive-container__cards__right__header">
          <div class="incentive-container__cards__right__header__img">
            <img
              [ngClass]="levelZeroFlag ? 'dimmed' : ''"
              src="{{ incentiveAssets + 'star.svg' }}"
            />
          </div>
          <div
            *ngIf="
              !currentIncentiveData.isIncentiveCapped ||
              (currentIncentiveData.isIncentiveCapped && !lastLevelFlag)
            "
            [ngClass]="levelZeroFlag ? 'dimmed' : ''"
            class="incentive-container__cards__right__header__description"
          >
            <p class="incentive-container__cards__right__header__description__top cap1--regular">
              انت الآن في
            </p>
            <p class="incentive-container__cards__right__header__description__bottom body1--bold">
              المستوى {{ currentIncentiveData.currentLevel || '-' }} من
              {{ currentIncentiveData.maximumLevel }}
            </p>
          </div>
          <div
            *ngIf="currentIncentiveData.isIncentiveCapped && lastLevelFlag"
            [ngClass]="levelZeroFlag ? 'dimmed' : ''"
            class="incentive-container__cards__right__header__description"
          >
            <p class="incentive-container__cards__right__header__description__top cap1--regular">
              لقد وصلت إلى المستوى الاخير
            </p>
            <p
              class="incentive-container__cards__right__header__description__bottom body1--bold"
              *ngIf="!lowDR"
            >
              تهانينا!
            </p>
          </div>
          <div
            class="incentive-container__cards__right__header__img"
            *ngIf="!currentIncentiveData.isIncentiveCapped"
          >
            <img
              [ngClass]="levelZeroFlag ? 'dimmed' : ''"
              src="{{ incentiveAssets + 'coin.svg' }}"
            />
          </div>
          <div
            *ngIf="!currentIncentiveData.isIncentiveCapped"
            [ngClass]="levelZeroFlag ? 'dimmed' : ''"
            class="incentive-container__cards__right__header__description"
          >
            <p class="incentive-container__cards__right__header__description__top cap1--regular">
              كل ١ طلب تم توصيله =
            </p>
            <p class="incentive-container__cards__right__header__description__bottom body1--bold">
              {{ currentIncentiveData.rewardPerOrder || '-' }} {{ currency | currencyShortName }}
            </p>
          </div>
          <div
            *ngIf="currentIncentiveData.isIncentiveCapped && !lastLevelFlag"
            [ngClass]="levelZeroFlag ? 'dimmed' : ''"
            class="incentive-container__cards__right__header__description"
          >
            <p class="incentive-container__cards__right__header__description__top cap1--regular">
              كل ١ طلب تم توصيله =
            </p>
            <p class="incentive-container__cards__right__header__description__bottom body1--bold">
              {{ currentIncentiveData.rewardPerOrder || '-' }} {{ currency | currencyShortName }}
            </p>
          </div>
        </div>
        <div *ngIf="levelZeroFlag" class="incentive-container__cards__right__body__header">
          <img src="{{ incentiveAssets + 'coin.svg' }}" />
          <p class="incentive-container__cards__right__body__header__text heading1--extra-bold">
            اهلاً بك في تحدي الاحتراف
          </p>
        </div>
        <p
          *ngIf="levelZeroFlag"
          class="incentive-container__cards__right__body__header__text body1--medium"
        >
          يرجى إكمال المتطلبات التالية لبدء تحقيق الربح في التحدي
        </p>
        <div class="incentive-container__cards__right__body">
          <div
            *ngIf="!lastLevelFlag"
            class="incentive-container__cards__right__body__card-big"
            [ngClass]="levelZeroFlag ? 'partial-width' : ''"
          >
            <div class="incentive-container__cards__right__body__card-big__delivered-orders">
              <p
                class="incentive-container__cards__right__body__card-big__delivered-orders__text heading2--bold"
              >
                طلبات تم توصيلها
              </p>
              <p
                class="incentive-container__cards__right__body__card-big__delivered-orders__number heading2--bold"
              >
                {{ currentIncentiveData.targetDeliveredOrders }}/{{
                  currentIncentiveData.deliveredOrders
                }}
              </p>
            </div>
            <div class="incentive-container__cards__right__body__card-big__loader">
              <div class="incentive-container__cards__right__body__card-big__loader__bar">
                <mat-progress-bar
                  class="progress-bar"
                  mode="determinate"
                  value="{{ runnerProgress }}"
                ></mat-progress-bar>
                <img
                  class="incentive-container__cards__right__body__card-big__loader__bar__runner"
                  [style.right.%]="(runnerProgress < 100 ? runnerProgress : 100) - 11"
                  src="{{ incentiveAssets + 'runner.svg' }}"
                />
              </div>
            </div>
            <div class="incentive-container__cards__right__body__card-big__orders">
              <p
                class="incentive-container__cards__right__body__card-big__orders__text heading2--bold"
              >
                {{ currentIncentiveData.deliveredOrders }} طلب
              </p>
              <p
                class="incentive-container__cards__right__body__card-big__orders__text heading2--bold"
              >
                {{ currentIncentiveData.targetDeliveredOrders }} طلب
              </p>
            </div>
          </div>
          <div
            *ngIf="levelZeroFlag"
            class="incentive-container__cards__right__body__card-level-zero"
          >
            <p
              class="incentive-container__cards__right__body__card-level-zero__text heading2--extra-bold"
            >
              الوصول لنسبة توصيل
              <span
                class="incentive-container__cards__right__body__card-level-zero__text-yellow heading2--extra-bold"
                >على الاقل {{ currentIncentiveData.minimumDeliveryRate }}%</span
              >
            </p>
            <div class="incentive-container__cards__left__box__loader">
              <div class="loader-container">
                <mat-progress-spinner
                  [ngClass]="lowDR ? 'low-dr-spinner' : 'high-dr-spinner'"
                  diameter="150"
                  strokeWidth="12"
                  mode="determinate"
                  value="{{ currentIncentiveData.deliveryRate }}"
                >
                </mat-progress-spinner>
                <mat-progress-spinner
                  class="spinner-bg"
                  value="100"
                  diameter="150"
                  strokeWidth="12"
                  mode="determinate"
                >
                </mat-progress-spinner>
              </div>
              <div
                class="incentive-container__cards__left__box__loader__percentage heading2--extra-bold"
              >
                {{ currentIncentiveData.deliveryRate }}%
              </div>
            </div>
          </div>
          <ng-container *ngIf="!levelZeroFlag">
            <div *ngIf="!lastLevelFlag" class="incentive-container__cards__right__body__card-small">
              <p class="incentive-container__cards__right__body__card-small__header body1--bold">
                استكمل الهدف للوصول الى المستوى {{ currentIncentiveData.nextLevel }}
              </p>
              <div class="incentive-container__cards__right__body__card-small__description">
                <p
                  class="incentive-container__cards__right__body__card-small__description__text body2--medium"
                >
                  كل طلب تم توصيله سيكون=
                </p>
                <p
                  class="incentive-container__cards__right__body__card-small__description__text body1--bold"
                >
                  {{ currentIncentiveData.nextRewardPerOrder }} {{ currency | currencyShortName }}
                </p>
                <img src="{{ incentiveAssets + 'coin.svg' }}" />
              </div>
            </div>
          </ng-container>
          <div
            *ngIf="lastLevelFlag"
            class="incentive-container__cards__right__body__card-level-six"
          >
            <img src="{{ incentiveAssets + 'last-level.svg' }}" />
            <p
              class="incentive-container__cards__right__body__card-level-six__text heading2--extra-bold"
            >
              لقد وصلت إلى المستوى الاخير
            </p>
            <div class="line"></div>
            <p
              *ngIf="rewardMultiplierIsAvailable"
              class="incentive-container__cards__right__body__card-level-six__text body1-regular"
            >
              الطلبات اللتي تم توصيلها حتى الآن:<span class="heading2--extra-bold">{{
                currentIncentiveData.deliveredOrders
              }}</span>
            </p>
            <p
              *ngIf="!rewardMultiplierIsAvailable && !lowDR"
              class="incentive-container__cards__right__body__card-level-six__text body1-regular"
            >
              تهانينا، انت وصلت إلى المستوى الاخير! ترقب التحدي القادم
            </p>
            <p
              *ngIf="!rewardMultiplierIsAvailable && lowDR"
              class="incentive-container__cards__right__body__card-level-six__text body1-regular"
            >
              انت وصلت إلى المستوى الاخير! ترقب التحدي القادم، ولكن قم بزيادة نسبة التوصيل إلى
              {{ currentIncentiveData.minimumDeliveryRate }}% او اكثر لتحصل على هذه الارباح
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="!levelZeroFlag" class="incentive-container__cards__left">
        <div class="incentive-container__cards__left__header">
          <p
            *ngIf="rewardMultiplierIsAvailable"
            class="incentive-container__cards__left__header__white body1--bold"
          >
            قم بزيادة
            <span class="incentive-container__cards__left__header__yellow body1--bold">
              نسبة التوصيل</span
            >
            الخاصة بك للحصول على مضاعفة للارباح تصل إلى
            <span class="incentive-container__cards__left__header__yellow body1--bold"> x 1.5</span>
          </p>
          <p
            *ngIf="!rewardMultiplierIsAvailable"
            class="incentive-container__cards__left__header__white body1--bold"
          >
            يجب أن يكون لديك نسبة توصيل
            <span class="incentive-container__cards__left__header__yellow body1--bold">
              {{ currentIncentiveData.minimumDeliveryRate }}%</span
            >
            أو اكثر لكسب ارباحك المتوقعة
          </p>
        </div>
        <div class="incentive-container__cards__left__box">
          <div class="incentive-container__cards__left__box__loader">
            <div class="loader-container">
              <mat-progress-spinner
                [ngClass]="lowDR ? 'low-dr-spinner' : 'high-dr-spinner'"
                diameter="150"
                strokeWidth="12"
                mode="determinate"
                value="{{ currentIncentiveData.deliveryRate }}"
              >
              </mat-progress-spinner>
              <mat-progress-spinner
                class="spinner-bg"
                value="100"
                diameter="150"
                strokeWidth="12"
                mode="determinate"
              >
              </mat-progress-spinner>
            </div>
            <div
              class="incentive-container__cards__left__box__loader__percentage heading2--extra-bold"
            >
              {{ currentIncentiveData.deliveryRate }}%
            </div>
          </div>
          <div
            *ngIf="rewardMultiplierIsAvailable"
            class="incentive-container__cards__left__box__bottom"
          >
            <img src="{{ incentiveAssets + 'z.svg' }}" />
            <p class="incentive-container__cards__left__box__bottom__text body2--medium">
              مضاعفة ارباحك حتى الآن ستكون:
            </p>
            <p class="incentive-container__cards__left__box__bottom__number heading1--bold">
              x {{ currentIncentiveData.bonusMultiplier }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!levelZeroFlag" class="incentive-container__rewards-card">
      <div class="incentive-container__rewards-card__header">
        <p class="incentive-container__rewards-card__header__top body1--bold">
          ارباحك المتوقعة حتى الآن
        </p>
        <div class="incentive-container__rewards-card__header__bottom">
          <img src="{{ incentiveAssets + 'coin.svg' }}" />
          <p
            class="incentive-container__rewards-card__text heading1--extra-bold"
            [ngClass]="lowDR ? 'dimmed' : ''"
          >
            {{ currentIncentiveData.expectedRewards }} {{ currency | currencyShortName }}
            <span *ngIf="currentIncentiveData.bonusMultiplier">X</span>
          </p>
          <p
            *ngIf="currentIncentiveData.bonusMultiplier"
            class="incentive-container__rewards-card__text heading1--extra-bold"
          >
            ({{ currentIncentiveData.bonusMultiplier }})
          </p>
          <img *ngIf="rewardMultiplierIsAvailable" src="{{ incentiveAssets + 'z.svg' }}" />
        </div>
      </div>
      <div class="incentive-container__rewards-card__content">
        <ul *ngIf="!lowDR">
          <li class="incentive-container__rewards-card__text body1--bold">
            ستحصل على ارباحك المتوقعة عند انتهاء هذا التحدي
          </li>
          <li class="incentive-container__rewards-card__text body1--bold">
            يجب أن يكون لديك نسبة توصيل
            <span class="incentive-container__rewards-card__text-yellow body1--bold"
              >{{ currentIncentiveData.minimumDeliveryRate }}%</span
            >
            أو اكثر لكسب هذه الارباح
          </li>
          <li
            *ngIf="rewardMultiplierIsAvailable"
            class="incentive-container__rewards-card__text body1--bold"
          >
            سيتم حسابة مضاعفة ارباحك حسب نسبة التوصيل يوم ٧ {{ nextMonth }}
          </li>
        </ul>
        <ul *ngIf="lowDR" class="incentive-container__rewards-card__content__low-dr">
          <li class="incentive-container__rewards-card__text body1--bold">
            قم بزيادة نسبة توصيل شهر {{ currentMonth }} إلى
            {{ currentIncentiveData.minimumDeliveryRate }}% او اكثر لتحصل على هذه الارباح
          </li>
          <img
            class="incentive-container__rewards-card__content__triangle"
            src="{{ incentiveAssets + 'triangle.svg' }}"
          />
        </ul>
      </div>
      <img
        class="incentive-container__rewards-card__img"
        src="{{ incentiveAssets + 'treasure.svg' }}"
      />
    </div>
    <div class="incentive-container__details">
      <p class="incentive-container__details__header heading1--extra-bold">التفاصيل</p>
      <div class="incentive-container__details__levels">
        <div class="incentive-container__details__levels__description">
          <div class="incentive-container__details__levels__description__header">
            <img src="{{ incentiveAssets + 'star.svg' }}" />
            <p class="incentive-container__details__header heading2--bold">المستويات</p>
          </div>
          <ul>
            <li class="incentive-container__details__levels__description__list body2-medium">
              مستوى اعلى يعني ربح اكثر على كل طلب تم توصيله
            </li>
          </ul>
        </div>
        <div class="incentive-container__details__levels__table">
          <table class="table d-none d-md-table">
            <thead>
              <tr>
                <th class="body1--regular" scope="col">المستوى</th>
                <th scope="col" class="table-center body1--regular">
                  الطلبات التي تم توصيلها المطلوبة
                </th>
                <th class="body1--regular" scope="col">ربحك على كل طلب تم توصيله</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let level of currentIncentiveData.levels">
                <td class="body1--regular content-main">
                  <img class="table-img" src="{{ incentiveAssets + 'star.svg' }}" />المستوى
                  <span class="body1--bold content-main">{{ level.level }}</span>
                </td>
                <td *ngIf="isKsaSelected" class="table-center body1--bold content-main">
                  {{ level.minOrders }} - {{ level.maxOrders }}
                </td>
                <td *ngIf="!isKsaSelected" class="table-center body1--bold content-main">
                  {{ level.minOrders }}
                </td>
                <td class="body1--regular content-main">
                  <img class="table-img" src="{{ incentiveAssets + 'coin.svg' }}" />{{
                    level.rewardPerOrder
                  }}{{ currency | currencyShortName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="rewardMultiplierIsAvailable">
      <div class="incentive-container__details">
        <div class="incentive-container__details__levels">
          <div class="incentive-container__details__levels__description">
            <div class="incentive-container__details__levels__description__header">
              <img src="{{ incentiveAssets + 'z.svg' }}" />
              <p class="incentive-container__details__header heading2--bold">مضاعفة الارباح</p>
            </div>
            <ul>
              <li class="incentive-container__details__levels__description__list body2-medium">
                مضافعة الارباح تنعكس فقط في تحدي الاحتراف
              </li>
              <li class="incentive-container__details__levels__description__list body2-medium">
                مضاعفة الارباح تعتمد على نسبة التوصيل
              </li>
              <li
                *ngIf="rewardMultiplierIsAvailable"
                class="incentive-container__details__levels__description__list body2-medium"
              >
                سيتم حسابة المضاعفة يوم ٧ في الشهر القادم
              </li>
            </ul>
          </div>
          <div class="incentive-container__details__levels__table">
            <table class="table d-none d-md-table">
              <thead>
                <tr>
                  <th class="body1--regular" scope="col">المضاعفة</th>
                  <th scope="col" class="table-center body1--regular">اقل نسبة توصيل مطلوبة</th>
                  <th class="body1--regular" scope="col">المتوقع</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let bonusLevel of currentIncentiveData.bonusLevels">
                  <td class="body1--bold content-main">
                    <img class="table-img" src="{{ incentiveAssets + 'z.svg' }}" />
                    ({{ bonusLevel.bonusMultiplier }}X)
                  </td>
                  <td class="table-center body1--bold content-main">
                    {{ bonusLevel.minDeliveryRate }}%
                  </td>
                  <td class="body1--regular content-main" *ngIf="bonusLevel.bonusPercentage === 0">
                    لا يوجد زيادة في الارباح
                  </td>
                  <td class="body1--regular content-main" *ngIf="bonusLevel.bonusPercentage !== 0">
                    زيادة
                    <span class="body1--bold secondary-dark"
                      >{{ bonusLevel.bonusPercentage }}%</span
                    >
                    في الارباح
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="incentive-container__accordion" *ngIf="previousIncentiveData">
    <mat-accordion>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header class="incentive-container__accordion__header">
          <mat-panel-title>
            <img src="{{ incentiveAssets + 'run.svg' }}" />
            <p class="incentive-container__accordion__header-start heading2--bold">
              {{ 'INCENTIVE_PROGRAM.PREVIOUS_INCENTIVE.HEADER.TEXT_1' | translate }}
            </p>
          </mat-panel-title>
          <p class="incentive-container__accordion__header-end body2--bold">
            {{ 'INCENTIVE_PROGRAM.PREVIOUS_INCENTIVE.HEADER.TEXT_2' | translate }}
          </p>
        </mat-expansion-panel-header>
        <p class="incentive-container__accordion__content body1--medium">
          {{ 'INCENTIVE_PROGRAM.PREVIOUS_INCENTIVE.CONTENT.TEXT_1' | translate }}
          <span class="incentive-container__accordion__content__date">
            {{ previousIncentiveDataDeliveryCalculationDate }}
          </span>
          {{ 'INCENTIVE_PROGRAM.PREVIOUS_INCENTIVE.CONTENT.TEXT_2' | translate }}
        </p>
        <div class="incentive-container__accordion__cards">
          <div class="incentive-container__accordion__cards__right">
            <div class="incentive-container__accordion__cards__right__text-container">
              <p class="incentive-container__accordion__cards__right__text__top body1--bold">
                {{ 'INCENTIVE_PROGRAM.PREVIOUS_INCENTIVE.CONTENT.TEXT_3' | translate }}
              </p>
              <p class="incentive-container__accordion__cards__right__text body1--medium">
                {{ 'INCENTIVE_PROGRAM.PREVIOUS_INCENTIVE.CONTENT.TEXT_4' | translate }}
                <span class="incentive-container__accordion__cards__right__text__condition">
                  {{ previousIncentiveData.minimumDeliveryRate }}%{{
                    'INCENTIVE_PROGRAM.PREVIOUS_INCENTIVE.CONTENT.TEXT_5' | translate
                  }}</span
                >
                {{ 'INCENTIVE_PROGRAM.PREVIOUS_INCENTIVE.CONTENT.TEXT_6' | translate }}
              </p>
            </div>
            <div>
              <div class="incentive-container__cards__left__box__loader">
                <div class="loader-container">
                  <mat-progress-spinner
                    [ngClass]="lowPreviousDR ? 'low-dr-spinner' : 'high-dr-spinner'"
                    diameter="150"
                    strokeWidth="12"
                    mode="determinate"
                    value="{{ previousIncentiveData.deliveryRate }}"
                  >
                  </mat-progress-spinner>
                  <mat-progress-spinner
                    class="spinner-bg"
                    value="100"
                    diameter="150"
                    strokeWidth="12"
                    mode="determinate"
                  >
                  </mat-progress-spinner>
                </div>
                <div
                  class="incentive-container__cards__left__box__loader__percentage heading2--extra-bold"
                >
                  {{ previousIncentiveData.deliveryRate }}%
                </div>
              </div>
            </div>
          </div>
          <div class="incentive-container__accordion__cards__left">
            <div class="incentive-container__accordion__cards__right__text-container">
              <p class="incentive-container__accordion__cards__right__text__top body1--bold">
                {{ 'INCENTIVE_PROGRAM.PREVIOUS_INCENTIVE.CONTENT.TEXT_7' | translate }}
              </p>
              <div
                class="incentive-container__accordion__cards__left__top"
                [ngClass]="lowPreviousDR ? 'dimmed' : ''"
              >
                <img src="{{ incentiveAssets + 'coin.svg' }}" />
                <p class="incentive-container__accordion__cards__right__text heading1--extra-bold">
                  {{ previousIncentiveData.totalRewards }} {{ currency | currencyShortName }}
                </p>
                <div
                  *ngIf="previousIncentiveData.bonusMultiplier"
                  class="incentive-container__accordion__cards__left__top"
                >
                  <p
                    class="incentive-container__accordion__cards__right__text heading1--extra-bold"
                  >
                    X
                  </p>
                  <p
                    class="incentive-container__accordion__cards__right__text heading1--extra-bold"
                  >
                    ({{ previousIncentiveData.bonusMultiplier }})
                  </p>
                  <img src="{{ incentiveAssets + 'z.svg' }}" />
                </div>
              </div>
            </div>
            <img
              class="incentive-container__accordion__cards__left__img"
              src="{{ incentiveAssets + 'treasure.svg' }}"
            />
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<app-multiple-dynamic-incentive-program
  *ngIf="isKsaSelected && isDynamicIncentiveProgramEnabled"
></app-multiple-dynamic-incentive-program>
<app-download-app class="d-block d-md-none"></app-download-app>
